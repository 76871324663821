import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Images
import logo from "img/jf-horiz.svg";

import "./index.scss";

export function Header({ y }) {
  const [isActive, setIsActive] = useState(false),
    [isHidden, setIsHidden] = useState(false),
    [previousScroll, setPreviousScroll] = useState(0);

  // a little magic to make the header appear when a user scrolls down
  useEffect(() => {
    y > previousScroll ? setIsHidden(true) : setIsHidden(false);
    setPreviousScroll(y);
  }, [y, previousScroll]);

  return (
    <React.Fragment>
      <div className={`header ${!isActive && isHidden ? "" : ""}`}>
        <Link className="header-logo-container" to="/#splash">
          <img id="logo" className="logo" src={logo} alt="logo" />
        </Link>
        <svg
          className={`hamburger ${isActive ? "cross" : ""}`}
          onClick={() => setIsActive(!isActive)}
          viewBox="0 0 800 600"
        >
          <path
            d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
            id="top"
          ></path>
          <path d="M300,320 L540,320" id="middle"></path>
          <path
            d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
            id="bottom"
            transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
          ></path>
        </svg>
        <nav className="desktop-links">
          <Link className="header-link" to="/#explore">
            <span>/</span> Feed
          </Link>
          <Link className="header-link" to="/#specs">
            <span>/</span> Grid
          </Link>
          <Link className="header-button" to="/inquire">
            Inquire
          </Link>
        </nav>
        <nav className={`mobile-nav ${isActive ? "isActive" : ""}`}>
          <Link
            className="header-link"
            to="/#explore"
            onClick={() => setIsActive(false)}
          >
            <span>/</span> Feed
          </Link>
          <Link
            className="header-link"
            to="/#specs"
            onClick={() => setIsActive(false)}
          >
            <span>/</span> Grid
          </Link>
          <Link className="header-button" to="/inquire">
            Inquire
          </Link>
        </nav>
      </div>
    </React.Fragment>
  );
}
