import React from "react";
import { Link } from "react-router-dom";

import Tilt from "react-parallax-tilt";

// Style
import s from "./index.module.scss";

export const IntroPicture = ({ image, backgroundColor, ...props }) => {
  return (
    <div
      className={s.introPictureBack}
      style={{ backgroundColor: `${backgroundColor}` }}
    >
      <Link to="/" className={s.homeLink}>{`< Home`}</Link>
      <div className={s.introPictureContainer}>
        <Tilt className="Tilt" tiltMaxAngleX="4.20" tiltMaxAngleY="4.20">
          <img alt="" className={s.introPicture} src={image} />
        </Tilt>
      </div>
    </div>
  );
};
