import React, { useEffect, useContext, useState, useRef } from "react";
import { ProjectsContext } from "contexts/ProjectsContext";
import { useParams, useNavigate, Link } from "react-router-dom";
import gsap from "gsap";
import { ProjectCard } from "components/ProjectCard";

import s from "./index.module.scss";
import { useEdgeManager } from "contexts/EdgeManager";
import { UserContext } from "contexts/UserContext";
import { fontWeight } from "utils/fontWeight";

export const Greeting = () => {
  var tl = gsap.timeline({ paused: false, id: "timeline" });
  const [greeting, setGreeting] = useState([]);
  const { projects, loaded, dataLoaded } = useContext(ProjectsContext);
  const { users, usersLoaded } = useContext(UserContext);

  const [selectedProjects, setSelectedProjects] = useState([]);
  const { setEdgeColor } = useEdgeManager();
  let { slug: urlSlug } = useParams(),
    navigate = useNavigate();

  const userSlugs = users.map((user) => {
    return user.slug;
  });

  const slugMatch = userSlugs.includes(urlSlug);
  console.log(slugMatch);

  const user = users.find(({ slug }) => {
    console.log(slug);
    return slug === urlSlug;
  });

  console.log(user);

  const textRef = useRef();

  useEffect(() => {
    fontWeight(textRef);
  }, []);

  return (
    <section className="home-hero">
      <Link to="/">{`< Home`}</Link>
      {user && (
        <>
          <h2 ref={textRef} className={s.message}>
            {user.message1}
          </h2>
          <div className={s.selectedProjects}>
            {user.selectedProjects.map((project, i) => (
              <ProjectCard project={project} key={i} />
            ))}
          </div>
        </>
      )}
    </section>
  );
};
