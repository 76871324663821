import React, { useEffect, useRef, useState, useContext } from "react";
import { Link } from "react-router-dom";

import { inDevelopment } from "utils/env-utils";

// Contexts
import { UserContext } from "contexts/UserContext";
import { ProjectsContext } from "contexts/ProjectsContext";

import { useSpring, animated } from "react-spring";

import "./index.scss";
import { Header } from "components/Header";

export const Feed = () => {
  const { users, usersLoaded } = useContext(UserContext);
  const { projects, loaded, dataLoaded } = useContext(ProjectsContext);

  const [focusedProj, setFocusedProj] = useState({ bg: "#FFFFFF", img: "" });
  const props = useSpring({ backgroundColor: focusedProj.bg });

  // Data testing
  if (inDevelopment) {
    console.group("Data Status");
    console.log(loaded ? "I'm done loading" : "I'm still loading");
    console.log(
      dataLoaded
        ? "and my data is done loading too!"
        : "but my data is still loading",
    );
    console.log(projects);
    console.groupEnd();
  }

  return (
    <div className="page-background">
      <animated.div className="page-container" style={{ ...props }}>
        <Header />
        {projects.map((project) => (
          <Link
            to={`/${project.slug}`}
            key={project.slug}
            className="intro-text"
            // onMouseEnter={() => setFocusedProj({bg:`${project.bannerImageBackgroundColor}`})} This is breaking. Do not use
          >
            Jono is {project.jonoIs}
          </Link>
        ))}
        <Link to={`/a-cool-guy`} key={"about"} className="intro-text">
          Who is Jono?
        </Link>
        <a href="/">
          <svg
            className="jf"
            data-name="jf-layer"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 60 140"
          >
            <title>jfLogo-05</title>
            <path d="M52,8V132H8V8H52m8-8H0V140H60V0h0Z" />
            <path d="M14,76h7.58C34.83,76,45,64.92,45,51.67V15H14V25H35V52c0,7.67-5.75,14-13.42,14H14V76" />
            <path d="M46,83H38.56C25.31,83,15,94.08,15,107.33V125H39.71L44,115H25v-8A13.7,13.7,0,0,1,38.56,93H46V83Z" />
          </svg>
        </a>
      </animated.div>
    </div>
  );
};
