import React from "react";
import { Link } from "react-router-dom";

import { useEdgeManager } from "contexts/EdgeManager";

// Style
import s from "./index.module.scss";

export const ProjectCard = ({ project }) => {
  const { setEdgeColor } = useEdgeManager();
  return (
    <Link
      to={`/${project.slug?.current}`}
      className={s.projectCard}
      onMouseEnter={() => setEdgeColor(project.bannerImageBackgroundColor.hex)}
    >
      <img src={project.tile} alt="tile" className={s.tile} />

      <h3 className={s.cardTitle}>{project.title}</h3>
    </Link>
  );
};
