import React from "react";
import appConfig from "app.config";
import BlockToReact from "@sanity/block-content-to-react";

// Components
import { OneOff } from "./OneOff";
import { HeaderAndBody } from "components/Portfolio/HeaderAndBody";
import { FullWidthImage } from "components/Portfolio/FullWidthImage";

// Style
import s from "./index.module.scss";

export const BodyContent = ({ className, blocks, ...props }) => {
  return (
    <div className={s.bodyContent}>
      <BlockToReact
        blocks={blocks}
        projectId={appConfig.sanity.id}
        dataset={appConfig.sanity.dataset}
        serializers={{
          types: {
            oneOff: OneOff,
            headerAndBody: HeaderAndBody,
            fullWidthImage: FullWidthImage,
          },
        }}
        {...props}
      />
    </div>
  );
};
