import React, { useState } from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";

import { getAppContainerID, inDevelopment } from "utils/env-utils";
import { ScrollToTop } from "./utils/ScrollToTop";

// Components & Modules
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import gsap from "gsap";
import { GSDevTools } from "gsap/GSDevTools";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import drawSVG from "gsap/DrawSVGPlugin";
import { Loader } from "components/Loader";

// Contexts
import { ProjectsProvider } from "contexts/ProjectsContext";
import { UserProvider } from "contexts/UserContext";
import { ModalManager } from "contexts/ModalManager";

// Pages
import { Home } from "./pages/Home";
import { Feed } from "./pages/Home/Feed";
import { Grid } from "./pages/Home/Grid";
import { WIP } from "./pages/WIP";
import { ProjectPage } from "./pages/ProjectPage";
import { About } from "./pages/About";
import { Greeting } from "./pages/Greeting";
import { NoMatch } from "./pages/NoMatch";

import { rainbow } from "utils/fun";
import { EdgeManager } from "contexts/EdgeManager";

import "style/index.scss";

export const App = () => {
  const [loaderDone, setLoaderDone] = useState(false);
  const [scrollDisabled, setScrollDisabled] = useState(true);

  if (typeof window !== `undefined`) {
    gsap.registerPlugin(SplitText, ScrollTrigger, GSDevTools, drawSVG);
    gsap.core.globals(
      "SplitText",
      SplitText,
      "ScrollTrigger",
      ScrollTrigger,
      "drawSVG",
      drawSVG,
    );
  }
  rainbow("Clever Girl! 🏆 ");
  return (
    <UserProvider>
      <ProjectsProvider>
        <ModalManager>
          <EdgeManager>
            <Router>
              <ScrollToTop />
              {!loaderDone && (
                <Loader
                  setScrollDisabled={setScrollDisabled}
                  setLoaderDone={setLoaderDone}
                />
              )}
              <Routes>
                <Route path="/" element={inDevelopment ? <Home /> : <WIP />} />
                <Route path="/feed" element={<Feed />} />
                <Route path="/grid" element={<Grid />} />
                <Route path="/workingonhiswebsite" element={<WIP />} />
                <Route path="/a-cool-guy" element={<About />} />
                <Route path="/:slug" element={<ProjectPage />} />
                <Route path="/showinghisworkto/:slug" element={<Greeting />} />
                <Route element={<NoMatch />} />
              </Routes>
            </Router>
          </EdgeManager>
        </ModalManager>
      </ProjectsProvider>
    </UserProvider>
  );
};

window.onload = () => {
  const domNode = document.getElementById("root");
  const root = createRoot(domNode);
  root.render(<App />);
};
