import gsap, { tl } from "gsap";
import SplitText from "gsap/SplitText";

export const fontWeight = (ref) => {
  var tl = gsap.timeline({ paused: false, id: "timeline" });
  const fontWeightItems = ref.current;
  console.log(fontWeightItems);
  const MAX_DISTANCE = 300; // Adjust the maximum distance for•
  const MAX_FONT_WEIGHT = 800; // Maximum font weight
  const MIN_FONT_WEIGHT = 300; // Minimum font weight

  //• Split up any text with the data attribute into individual. fontWeightItems. forEach ( (item) =>• {

  const mySplitText = new SplitText(ref.current, { type: "words,chars" }),
    chars = mySplitText.chars;

  tl.fromTo(
    chars,
    {
      duration: 1,
      scale: 0,

      transformOrigin: "0% 50% -50",
      y: 20,

      fontWeight: 100,
      ease: "back.out",
    },
    {
      scale: 1,
      transformOrigin: "0",

      fontWeight: 800,
      y: 0,
      stagger: 0.01,
    },
  ).to(chars, { fontWeight: 100, stagger: 0.01 }, "-=0.8");

  document.addEventListener("mousemove", (event) => {
    const mouseX = event.pageX;
    const mouseY = event.pageY;

    chars.forEach((char) => {
      //Get the center of each character and calculate the distance.
      const itemRect = char.getBoundingClientRect();
      const itemCenterX = itemRect.left + itemRect.width / 2 + window.scrollX;
      const itemCenterY = itemRect.top + itemRect.height / 2 + window.scrollY;

      const distance = Math.sqrt(
        Math.pow(mouseX - itemCenterX, 2) + Math.pow(mouseY - itemCenterY, 2),
      );

      // Map the distance to the font weight range
      let fontWeight =
        distance < MAX_DISTANCE
          ? gsap.utils.mapRange(
              0,
              MAX_DISTANCE,
              MIN_FONT_WEIGHT,
              MAX_FONT_WEIGHT,
              Math.max(0, MAX_DISTANCE - distance),
            )
          : MIN_FONT_WEIGHT;
      gsap.to(char, { fontWeight: fontWeight, duration: 0.5 });
    });
  });
};
