import React, { useEffect, useRef, useState, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { useMouse } from "react-use";
import { fontWeight } from "utils/fontWeight";

import gsap from "gsap";
import { SplitText } from "gsap/SplitText";

// Contexts
import { UserContext } from "contexts/UserContext";
import { ProjectsContext } from "contexts/ProjectsContext";

import { useSpring } from "react-spring";
import { useEdgeManager } from "contexts/EdgeManager";

import "./index.scss";

export const Home = () => {
  var tl = gsap.timeline({ paused: false, id: "timeline" });
  const { users, usersLoaded } = useContext(UserContext),
    { projects, loaded, dataLoaded } = useContext(ProjectsContext);

  console.log(users);

  const { setEdgeColor, scrollDisabled, setEdge } = useEdgeManager();

  useEffect(() => {
    setEdgeColor("white");
    setEdge(true);
  }, [setEdge, setEdgeColor]);

  useEffect(() => {
    fontWeight(textRef);
  }, []);

  const textRef = useRef();
  useEffect(() => {
    tl.to(".button", {
      opacity: 1,
      stagger: 0.4,
      duration: 1,
    });

    if (!scrollDisabled && usersLoaded) {
      tl.play();
    }
  }, [scrollDisabled, tl, usersLoaded]);

  return (
    <section className="home-hero">
      <h2 ref={textRef} className="greeting">
        Jono is a full-stack product designer, frontend developer, and creative
        generalist.
        <br></br>
        <br></br>
        Who are you?
      </h2>
      <div className="hello-buttons">
        {users.map((user, i) => (
          <Link
            className="button"
            key={i}
            to={`/showinghisworkto/${user.slug}`}
            onPointerEnter={() => setEdgeColor("red")}
            onPointerLeave={() => setEdgeColor("white")}
          >
            {user.username}
          </Link>
        ))}
        <Link
          className="button"
          to="/grid"
          onPointerEnter={() => setEdgeColor("purple")}
          onPointerLeave={() => setEdgeColor("blue")}
        >
          🤷🏻‍♂️ Just looking
        </Link>
      </div>
    </section>
  );
};
