import React, { useState, useContext, useRef } from "react";
import classnames from "classnames/bind";
import { useSpring, animated } from "react-spring";

// IMPORT LAST to benefit from cascading style overrides
import s from "./index.module.scss";

export const EdgeContext = React.createContext({
  setEdgeColor: () => {},
});

export const EdgeManager = ({ children }) => {
  const [edgeColor, setEdgeColor] = useState("white");
  const [edge, setEdge] = useState(true);
  const [scrollDisabled, setScrollDisabled] = useState(true);

  return (
    <EdgeContext.Provider
      value={{ setEdgeColor, scrollDisabled, setScrollDisabled, setEdge }}
    >
      <animated.div
        className={`${s.pageBackground} ${edge ? "" : s.hidden}`}
        style={{ backgroundColor: edgeColor }}
      >
        <div
          className={`${s.pageContainer} ${scrollDisabled ? "no-scroll" : ""}`}
        >
          {children}
        </div>
      </animated.div>
    </EdgeContext.Provider>
  );
};

export const useEdgeManager = () => {
  return useContext(EdgeContext);
};
