import React from "react";

import { IntroDescription } from "./IntroDescription";

import { gradientStyleToRight, gradientStyleToBottom } from "utils/style-utils";

// Style
import s from "./index.module.scss";

export const Intro = ({
  title,
  clientName,
  description,
  headline,
  hats,
  link,
  gradientValues,
  ...props
}) => {
  return (
    <div className={s.introContainer}>
      {gradientValues && (
        <div
          className={s.projectGradientTop}
          style={{ backgroundImage: gradientStyleToRight(gradientValues) }}
        />
      )}
      <div className={s.intro}>
        {/* AREA 1: CLIENT INFO */}
        <div className={s["area-1"]}>
          <div className={s.clientName}>
            <h2>
              <a href={link}>{clientName}</a>
            </h2>
          </div>

          <div className={s.title}>
            <div
              className={s.projectGradientSide}
              style={{ backgroundImage: gradientStyleToBottom(gradientValues) }}
            ></div>
            <h1>{title}</h1>
          </div>
        </div>

        {/* AREA 2: VISIT */}
        <div className={s["area-2"]}>
          <div className={s["visit"]}>
            <h3 size="s7" family="source-sans" weight="normal" color="white">
              <a href={link}>visit site</a>
            </h3>
          </div>
        </div>

        {/* AREA 3: PARAGRAPH */}
        <div className={s.clientBody}>
          <IntroDescription blocks={description} />
        </div>

        {/* AREA 4: HATS*/}
        <div className={s["area-4"]}>
          <div className={s.hats}>
            <h4 size="s7" family="source-sans" weight="normal" color="grey-2">
              Hats worn:
            </h4>
            {hats.map((hat, i) => (
              <React.Fragment key={i}>
                <h5
                  className={s.hat}
                  size="s7"
                  family="source-sans"
                  weight="bold"
                  color="white"
                >
                  {hat}
                </h5>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
